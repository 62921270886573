import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "@reach/router";
import { PageLayout } from "~components/templates/page-layout";
import { VoiceListWithFilter } from "~components/assessment/voice-list-with-filter";
import { withContext } from "~context";

import * as styles from "./index.module.scss";
import { graphql } from "gatsby";

type Props = {
  data: GatsbyAssessmentVoicePageQuery;
  pageTitle: string;
};

const Component = (props: Props) => {
  const location = useLocation();
  const [pageTitle] = useState<string>("セキュリティ診断事例インタビュー");
  const { defaultIndustry, defaultPlan } = useMemo(() => {
    if (location.href === undefined) {
      return {
        defaultIndustry: undefined,
        defaultPlan: undefined,
      };
    }

    const url = new URL(location.href);
    const defaultIndustry = url.searchParams.get("industry") ?? undefined;
    const defaultPlan = url.searchParams.get("plan") ?? undefined;

    return {
      defaultIndustry,
      defaultPlan,
    };
  }, [location]);

  const data = useMemo(() => {
    return props.data.allContentfulVoice.nodes.flatMap((node) => {
      const industryFilter =
        defaultIndustry === undefined
          ? true
          : node.industry?.some(
              (industry) => industry?.slug === defaultIndustry,
            );
      const planFilter =
        defaultPlan === undefined
          ? true
          : node.plan?.some((plan) => plan?.slug === defaultPlan);
      return industryFilter && planFilter
        ? {
            ...node,
            link: `/assessment/voice/${node.slug}`,
          }
        : [];
    });
  }, [props.data, defaultIndustry, defaultPlan]);

  return (
    <PageLayout
      paddingPattern="fit-header"
      meta={{
        title: pageTitle,
        description:
          "Flatt Securityのセキュリティ診断の事例インタビューを紹介します。お客様ごとに課題は様々ですが、課題解決としてFlatt Securityのどのような点が評価されているかがわかります。",
      }}
    >
      <main className={styles.AssessmentVoice_Wrapper}>
        <h1 className={styles.AssessmentVoice_Title}>診断事例インタビュー</h1>
        <VoiceListWithFilter
          defaultIndustry={defaultIndustry}
          defaultPlan={defaultPlan}
          data={data}
        />
      </main>
    </PageLayout>
  );
};

export const query = graphql`
  query AssessmentVoicePage {
    allContentfulVoice(filter: { category: { slug: { eq: "assessments" } } }) {
      nodes {
        ...VoiceQuery
      }
    }
    contentfulVoicePlan {
      name
    }
    contentfulVoiceIndustry {
      name
    }
  }
`;

export default withContext(Component);
